export const Pages={
    access:{
        displayName:"アクセス",
        path:"/access",
        icon:`${process.env.PUBLIC_URL}/img/menuBarIcon/accessIcon.png`,
    },
    event:{
        displayName:"ステージ企画",
        path:"/event",
        icon:`${process.env.PUBLIC_URL}/img/menuBarIcon/eventIcon.png`,
    },
    home:{
        displayName:"ホーム",
        path:"/",
        icon:`${process.env.PUBLIC_URL}/img/menuBarIcon/homeIcon.png`,
    },
    map:{
        displayName:"マップ",
        path:"/map",
        icon:`${process.env.PUBLIC_URL}/img/menuBarIcon/mapIcon.png`,
    },
    project:{
        displayName:"企画",
        path:"/project",
        icon:`${process.env.PUBLIC_URL}/img/menuBarIcon/projectIcon.png`,
    },
    credit:{
        displayName:"クレジット",
        path:"/credit",
    },
    inquiry:{
        displayName:"お問合せ",
        path:"/inquiry",
    },
    apologize:{
        displayName:"作成中",
        path:"/apologize",
    },
    projectDetail:{
        displayName:"企画詳細",
        path:"/projectDetail",
    },
    page404:{
        displayName:"404",
        path:"*",
    }
};
